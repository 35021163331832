import React, { lazy, Suspense } from "react";
import styles from "./App.module.css";
import ScrollToTopRoute from "./Components/ScrollTop/ScrollTop";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Pricing from "./Components/Pricing/Pricing";
import HowWorks from "./Components/HowWorks/HowWorks";
import DeepLinks from "./Components/DeepLinks";
import countryData from "./countryData";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";

import BranchDetail from "./Components/Office/BranchDetail";
import AppointmentDoneSuccess from "./Components/Appointment/AppointmentDoneSuccess";
import AppointmentDone from "./Components/AppointmentDone/AppointmentDone";
import AppContext from "./context";
import { ViewCalendar } from "./Components/Test/ViewCalendar/ViewCalendar";
import { Shell } from "./Components/Test/Shell/Shell";
import { Fin } from "./Components/Test/Fin/Fin";
import { FinishAppointment } from "./Components/Test/FinishAppoinment/FinishAppointment";
import Context from "./Components/Test/Context";
import CookiesNotice from './Components/CookiesNotice/CookiesNotice'

const set1stDataLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify([value]));
};

const isNull = (data) => data === null;

const getDataFromLocalStorage = (key) => {
  const data = JSON.parse(window.localStorage.getItem(key));
  return isNull(data) ? [] : data;
};

const set1stOrganicLocalStorage = (key) => {
  window.localStorage.setItem(key, JSON.stringify(["organico"]));
};

const setDataLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const proccessDataLocalStorage = (name, data) => {
  if (isNull(data)) {
    const dataFromLocalStorage = getDataFromLocalStorage(name);
    if (dataFromLocalStorage.length > 0) {
      dataFromLocalStorage.push("organico");
      setDataLocalStorage(name, dataFromLocalStorage);
    } else {
      set1stOrganicLocalStorage(name);
    }
  } else {
    const dataFromLocalStorage = getDataFromLocalStorage(name);
    if (dataFromLocalStorage.length < 15) {
      dataFromLocalStorage.push(data);
      setDataLocalStorage(name, dataFromLocalStorage);
    } else {
      set1stDataLocalStorage(name, data);
    }
  }
};

const saveUtm_info = (
  source,
  campain,
  medium,
  creative,
  payment,
  appointment,
  content
) => {
  proccessDataLocalStorage("utm_source", source);
  proccessDataLocalStorage("utm_campaign", campain);
  proccessDataLocalStorage("utm_medium", medium);
  proccessDataLocalStorage("creative", creative);
  proccessDataLocalStorage("payment_source", payment);
  proccessDataLocalStorage("appointment_source", appointment);
  proccessDataLocalStorage("utm_content", content);
};

function App(props) {
  const getCountry = () => {
    let country = window.location.href;
    // country = "https://mymoons.co";
    if (country.includes("mymoons.mx")) {
      document.documentElement.lang = "es-mx";
      return "mx";
    } else if (country.includes("mymoons.cl")) {
      document.documentElement.lang = "es-cl";
      return "cl";
    } else return "mx";
  };

  let country = getCountry();

  const url = new URL(window.location.href);
  const utm_source = url.searchParams.get("utm_source");
  const utm_campaign = url.searchParams.get("utm_campaign");
  const utm_medium = url.searchParams.get("utm_medium");
  const utm_content = url.searchParams.get("utm_content");
  const creative = url.searchParams.get("creative");
  const appointment = url.searchParams.get("appointment_source");
  const payment = url.searchParams.get("payment_source");
  saveUtm_info(
    utm_source,
    utm_campaign,
    utm_medium,
    creative,
    payment,
    appointment,
    utm_content
  );

  let Footer = lazy(() => import("./Components/Footer/Footer"));
  let Privacy = lazy(() => import("./Components/Privacy/Privacy"));
  let RefinementPolicy = lazy(() => import("./Components/RefinementPolicy/RefinementPolicy"));
  let PrivacyApp = lazy(() => import("./Components/Privacy/PrivacyApp"));
  let Conditions = lazy(() => import("./Components/Conditions/Conditions"));
  let Discounts = lazy(() => import("./Components/Conditions/Discounts"));
  let ConditionsHotsale = lazy(() => import("./Components/Conditions/ConditionsHotsale"));
  let ConditionsApp = lazy(() =>
    import("./Components/Conditions/ConditionsApp")
  );
  let ConditionsReferals = lazy(() =>
    import("./Components/Conditions/Referals")
  );
  let Returns = lazy(() => import("./Components/Returns/Returns"));


  return (
    <AppContext.Provider value={{ country, countryData }}>
      <Context.Provider>
        <BrowserRouter>
          <Router>
            <Switch>
              <ScrollToTopRoute exact path="/politica-refinamiento">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <RefinementPolicy />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/aviso-de-privacidad">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <Privacy />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/terminos-y-condiciones">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <Conditions />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/descuentos">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <Discounts />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/terminos-y-condiciones-hotsale">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <ConditionsHotsale />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/terminos-y-condiciones-moons-app">
                <div>
                  <Suspense fallback={<div></div>}>
                    <ConditionsApp />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/aviso-de-privacidad-moons-app">
                <div>
                  <Suspense fallback={<div></div>}>
                    <PrivacyApp />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/politica-de-devoluciones">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <Returns country={country} />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute
                exact
                path="/politica-de-devoluciones-moons-app"
              >
                <div>
                  <Suspense fallback={<div></div>}>
                    <Returns country={country} />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/terminos-y-condiciones-referidos">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <ConditionsReferals />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <ScrollToTopRoute exact path="/referidos">
                <div className={styles.mainContent}>
                  <Suspense fallback={<div></div>}>
                    <ConditionsReferals />
                  </Suspense>
                </div>
              </ScrollToTopRoute>
              <Route path="/" render={() => <Redirect to="/" />} />
            </Switch>
            <Suspense fallback={<div />}>
              <CookiesNotice />
            </Suspense>
          </Router>
        </BrowserRouter>
      </Context.Provider>
    </AppContext.Provider>
  );
}

export default App;
